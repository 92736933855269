import {
  borders,
  bottom,
  color,
  flexbox,
  position,
  space,
  layout,
  LayoutProps,
  SpaceProps,
  FlexboxProps,
  ColorProps,
  BordersProps,
  PositionProps,
} from 'styled-system'
import styled from 'styled-components'

export type BlockProps = LayoutProps & SpaceProps & FlexboxProps & ColorProps & BordersProps & PositionProps

export const Block = styled.div<BlockProps>`
  ${flexbox}
  ${layout}
  ${color}
  ${space}
  ${position}
  ${bottom}
  ${borders}
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

type SectionProps = {
  noVerticalMargin?: boolean
}

export const Section = styled.section<SectionProps>`
  padding: ${({ noVerticalMargin }) => (noVerticalMargin ? '0 15%' : '50px 15%')};
  width: 100%;
  @media screen and (max-width: 1024px) {
<<<<<<< HEAD
    padding: 50px 5%;
=======
    padding: ${({ noVerticalMargin }) => (noVerticalMargin ? '0 5%' : '25px 5%')};
  }
  @media screen and (max-width: 450px) {
    padding: ${({ noVerticalMargin }) => (noVerticalMargin ? '0 5%' : '25px 5%')};
  }
`

export const TextBox = styled.div`
  margin: 10px 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 20px;
  }
  p {
    color: ${({ theme }) => theme.colors.grey};
  }
  h2 {
    font-size: 45px;
    line-height: 54px;
    @media screen and (max-width: 450px) {
      font-size: 28px;
      line-height: 28px;
    }
  }
  h3 {
    font-size: 30px;
    line-height: 36px;
    @media screen and (max-width: 450px) {
      font-size: 21px;
      line-height: 21px;
    }
  }
  .image {
    display: flex;
    justify-content: center;
    margin: 20px 0;
>>>>>>> develop
  }
`
